import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { DeviceDto } from 'core'
import { uniq } from 'lodash'
import { useApi } from '~/modules/shared/composables/useApi'
import { useEventBus } from '~/modules/shared/composables/useEventBus.ts'

export const useDeviceStore = defineStore('device', () => {
  const devices = ref<DeviceDto[]>([])

  const fetchDevices = (): Promise<DeviceDto[]> => {
    return useApi()
      .get<DeviceDto[]>('/device/list')
      .then((response: DeviceDto[]) => (devices.value = response))
  }

  const deviceTags = computed<string[]>(() => {
    return uniq(
      devices.value.flatMap((device: DeviceDto) => device.tags),
    ).sort()
  })

  useEventBus().on('logout', (): void => {
    devices.value = []
  })

  return {
    devices,
    fetchDevices,
    deviceTags,
  }
})
