import type { TelemetryDto } from 'core'
import { hasDefinedTelemetryLocation } from 'core'
import { convertHoursToTime } from '~/modules/shared/utils'

export const calculateTelemetryBmsHoursLeft = (
  telemetry: TelemetryDto,
): string | null => {
  if (telemetry.bmsTotalCapacity <= 0 || telemetry.bmsCurrent >= 0) {
    return null
  }

  const hoursLeft: number = Math.abs(
    telemetry.bmsTotalCapacity / telemetry.bmsCurrent,
  )

  if (hoursLeft <= 0) {
    return null
  }

  return convertHoursToTime(hoursLeft)
}

export const hasTelemetryErrors = (telemetry: TelemetryDto): boolean => {
  return (
    telemetry.bmsIsFault
    || telemetry.inverterIsFault
    || !hasDefinedTelemetryLocation(telemetry)
  )
}
