import { StatusCodes } from 'http-status-codes'
import { useRuntimeConfig } from '#imports'
import { useUserStore } from '~/modules/auth/stores/user'

export const useApi = () => {
  const { apiBaseUrl } = useRuntimeConfig().public

  const makeUrl = (url: string): string => {
    return apiBaseUrl + url
  }

  const client = $fetch.create({
    baseURL: apiBaseUrl,
    credentials: 'include',
    onResponseError({ response }) {
      if (StatusCodes.UNAUTHORIZED === response.status) {
        useUserStore().resetUser()
      }
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const get = <T>(url: string, query?: Record<string, any>) => {
    return client<T>(url, {
      method: 'GET',
      query,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const post = <T>(url: string, body?: Record<string, any>) => {
    return client<T>(url, {
      method: 'POST',
      body,
    })
  }

  const sse = (url: string): EventSource => {
    return new EventSource(makeUrl(url), {
      withCredentials: true,
    })
  }

  return {
    makeUrl,
    get,
    post,
    sse,
  }
}
