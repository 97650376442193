import { isSameDay, isSameYear } from 'date-fns'
import { useDateStore } from '~/modules/shared/stores/date'

const timeZone: string = 'Europe/Kiev'

const locales: string = 'uk-UA'

export const formatDate = (date: Date): string => {
  return date.toLocaleDateString(locales, {
    day: 'numeric',
    month: 'short',
    year: !isSameYear(date, useDateStore().makeDate()) ? 'numeric' : undefined,
    timeZone,
  })
}

export const formatDateTime = (date: Date | number | string): string => {
  if ('number' === typeof date) {
    date = new Date(date * 1000)
  }

  if ('string' === typeof date) {
    date = new Date(date)
  }

  const formattedTime: string = date.toLocaleTimeString(locales, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone,
  })

  if (isSameDay(useDateStore().makeDate(), date)) {
    return formattedTime
  }

  return formatDate(date) + ' ' + formattedTime
}

export const convertHoursToTime = (number: number): string => {
  const hour: number = Math.floor(number)
  const min: number = 1 / 60
  const decimalPart: number = min * Math.round((number - hour) / min)

  let minute = Math.floor(decimalPart * 60) + ''

  if (minute.length < 2) {
    minute = '0' + minute
  }

  return hour + ':' + minute
}
