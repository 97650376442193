import validate from "/srv/www/watchpoint/prod/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth from "/srv/www/watchpoint/prod/apps/web/src/modules/auth/middlewares/auth.ts";
import device_45context_45load from "/srv/www/watchpoint/prod/apps/web/src/modules/device/middlewares/deviceContextLoad.ts";
import event_45context_45load from "/srv/www/watchpoint/prod/apps/web/src/modules/event/middlewares/eventContextLoad.ts";
import title from "/srv/www/watchpoint/prod/apps/web/src/modules/shared/middlewares/title.ts";
import date from "/srv/www/watchpoint/prod/apps/web/src/modules/shared/middlewares/date.ts";
import manifest_45route_45rule from "/srv/www/watchpoint/prod/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth,
  device_45context_45load,
  event_45context_45load,
  title,
  date,
  manifest_45route_45rule
]
export const namedMiddleware = {}