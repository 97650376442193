import { defineNuxtRouteMiddleware, navigateTo, useError } from '#imports'
import { useUserStore } from '~/modules/auth/stores/user'
import { Route } from '~/modules/shared/types.ts'

export default defineNuxtRouteMiddleware(async (to) => {
  if (useError().value) {
    return
  }

  const { fetchUser, hasUser } = useUserStore()

  if (!hasUser()) {
    try {
      await fetchUser()
    }
    catch (e) {
      console.log(e)
    }
  }

  if (!to.meta.guestOnly) {
    if (!hasUser()) {
      return navigateTo({ name: Route.LOGIN })
    }
  }
  else if (hasUser()) {
    return navigateTo({ name: Route.DEVICE_MAP })
  }
})
