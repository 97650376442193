import type { RouteLocationNamedRaw } from 'vue-router'

export interface MenuItem {
  name: string
  route: Omit<RouteLocationNamedRaw, 'name'> & { name: string }
  relatedRoutes?: string[]
  count?: number
}

export type MenuStubItem = Pick<MenuItem, 'name'>

export enum FetchStatus {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}

export enum Layout {
  AUTH = 'auth',
}

export enum Route {
  LOGIN = 'login',
  DEVICE_MAP = 'device-map',
  DEVICES_SETTINGS_LIST = 'devices-settings-list',
  DEVICE_GENERAL_SETTINGS = 'device-general-settings',
  DEVICE_COMMAND_SETTINGS = 'device-command-settings',
  DEVICES_ANALYTICS_LIST = 'devices-analytics-list',
  DEVICE_ANALYTICS_GRAPH = 'device-analytics-graph',
  EVENT_LIST = 'event-list',
}
