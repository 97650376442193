import mitt from 'mitt'
import type { DeviceDto, EventDto } from 'core'

type EventMap = {
  monitorDeviceClicked: DeviceDto
  eventCreated: EventDto
  logout: undefined
}

const emitter = mitt<EventMap>()

export const useEventBus = () => ({
  ...emitter,
})
