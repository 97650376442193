import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useApi } from '~/modules/shared/composables/useApi'

export const useDateStore = defineStore('date', () => {
  const offset = ref<number | null>(null)

  const fetchOffset = async (): Promise<void> => {
    return useApi()
      .get<string>('/shared/date')
      .then((response: string): void => {
        offset.value = new Date().getTime() - new Date(response).getTime()
      })
  }

  const hasOffset = (): boolean => {
    return null !== offset.value
  }

  const makeDate = (): Date => {
    return new Date(new Date().getTime() + offset.value!)
  }

  return {
    fetchOffset,
    hasOffset,
    makeDate,
  }
})
