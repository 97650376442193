import { defineNuxtRouteMiddleware } from '#imports'
import { useUserStore } from '~/modules/auth/stores/user.ts'
import { FetchStatus } from '~/modules/shared/types.ts'
import { useEventStore } from '~/modules/event/store/event.ts'

export default defineNuxtRouteMiddleware(async (): Promise<void> => {
  if (!useUserStore().hasUser()) {
    return
  }

  const {
    unreadEventsCountFetchStatus,
    fetchUnreadEventsCount,
    hasEventStream,
    openEventStream,
  } = useEventStore()

  if (FetchStatus.IDLE === unreadEventsCountFetchStatus) {
    fetchUnreadEventsCount()
  }

  if (!hasEventStream()) {
    openEventStream()
  }
})
