import revive_payload_client_YTGlmQWWT5 from "/srv/www/watchpoint/prod/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/srv/www/watchpoint/prod/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/srv/www/watchpoint/prod/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/srv/www/watchpoint/prod/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/srv/www/watchpoint/prod/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/srv/www/watchpoint/prod/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/srv/www/watchpoint/prod/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/srv/www/watchpoint/prod/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/srv/www/watchpoint/prod/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/srv/www/watchpoint/prod/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/srv/www/watchpoint/prod/apps/web/.nuxt/floating-vue.mjs";
import plugin_d0CIEMs0Lm from "/srv/www/watchpoint/prod/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import sentry_client_H7JebPKzSG from "/srv/www/watchpoint/prod/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/srv/www/watchpoint/prod/apps/web/.nuxt/sentry-client-config.mjs";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  floating_vue_EIcJ7xiw0h,
  plugin_d0CIEMs0Lm,
  sentry_client_H7JebPKzSG,
  sentry_client_config_o34nk2sJbg
]