import { defineNuxtRouteMiddleware } from '#imports'
import { useDateStore } from '~/modules/shared/stores/date'

export default defineNuxtRouteMiddleware(async () => {
  const { fetchOffset, hasOffset } = useDateStore()

  if (!hasOffset()) {
    await fetchOffset()
  }
})
