import type { DeviceDto, TelemetryDto } from 'core'
import { computed, type ComputedRef, reactive, watch } from 'vue'
import { defineStore } from 'pinia'
import { useDeviceStore } from '~/modules/device/stores/device'
import { useTelemetryStore } from '~/modules/telemetry/store/telemetry'
import { useDateStore } from '~/modules/shared/stores/date'
import { DeviceStatus } from '~/modules/device/types.ts'
import { useEventBus } from '~/modules/shared/composables/useEventBus.ts'
import { hasTelemetryErrors } from '~/modules/telemetry/utils'

export const useDeviceStatusStore = defineStore('deviceStatus', () => {
  const deviceStore = useDeviceStore()

  const blackTimeout = reactive<
    Record<string, ReturnType<typeof setTimeout> | true>
  >({})

  const blackTelemetryTimeout: number = 1000 * 15

  const getTelemetryTimePassed = (telemetry: TelemetryDto): number => {
    return (
      useDateStore().makeDate().getTime()
      - new Date(telemetry.timestamp * 1000).getTime()
    )
  }

  const initDeviceStatuses = () => {
    deviceStore.devices.forEach((device) => {
      const telemetry: ComputedRef<TelemetryDto | null>
        = useTelemetryStore().getDeviceTelemetry(device)

      watch(
        telemetry,
        () => {
          if (null === telemetry.value) {
            return
          }

          if ('number' === typeof blackTimeout[device.id]) {
            clearTimeout(
              blackTimeout[device.id] as ReturnType<typeof setTimeout>,
            )
          }

          const telemetryTimePassed: number = getTelemetryTimePassed(
            telemetry.value,
          )

          if (telemetryTimePassed < blackTelemetryTimeout) {
            blackTimeout[device.id] = setTimeout(
              () => (blackTimeout[device.id] = true),
              blackTelemetryTimeout - telemetryTimePassed,
            )
          }
        },
        { immediate: true },
      )
    })
  }

  const getDeviceStatuses = (
    device: DeviceDto,
  ): ComputedRef<DeviceStatus[]> => {
    return computed(() => {
      const deviceStatuses: DeviceStatus[] = []

      const telemetry: ComputedRef<TelemetryDto | null>
        = useTelemetryStore().getDeviceTelemetry(device)

      if (null === telemetry.value) {
        deviceStatuses.push(DeviceStatus.HAS_NO_UPDATES)

        return deviceStatuses
      }

      if (
        true === blackTimeout[device.id]
        || getTelemetryTimePassed(telemetry.value) > blackTelemetryTimeout
      ) {
        deviceStatuses.push(DeviceStatus.HAS_NO_UPDATES)
      }

      if (hasTelemetryErrors(telemetry.value)) {
        deviceStatuses.push(DeviceStatus.HAS_FAULTS)
      }

      if (0 === telemetry.value.inverterVoltageInput) {
        deviceStatuses.push(DeviceStatus.HAS_NO_VOLTAGE)
      }

      if (telemetry.value.isDoorOpen) {
        deviceStatuses.push(DeviceStatus.HAS_OPEN_DOORS)
      }

      if (!telemetry.value.isPowerOn) {
        deviceStatuses.push(DeviceStatus.HAS_POWER_OUTAGE)
      }

      if (0 === deviceStatuses.length) {
        deviceStatuses.push(DeviceStatus.HAS_NO_ISSUE)
      }

      return deviceStatuses
    })
  }

  const getDeviceCountByStatus = (
    status: DeviceStatus,
  ): ComputedRef<number> => {
    return computed(() => {
      let count = 0

      deviceStore.devices.forEach((device: DeviceDto) => {
        if (getDeviceStatuses(device).value.includes(status)) {
          count++
        }
      })

      return count
    })
  }

  useEventBus().on('logout', (): void => {
    Object.keys(blackTimeout).forEach((key: string): void => {
      if (true !== blackTimeout[key]) {
        clearTimeout(blackTimeout[key])
      }

      delete blackTimeout[key]
    })
  })

  return {
    initDeviceStatuses,
    getDeviceStatuses,
    getDeviceCountByStatus,
  }
})
