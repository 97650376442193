import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { EventDto } from 'core'
import { useApi } from '~/modules/shared/composables/useApi.ts'
import { useEventBus } from '~/modules/shared/composables/useEventBus.ts'
import { FetchStatus } from '~/modules/shared/types.ts'

export const useEventStore = defineStore('event', () => {
  const unreadEventsCount = ref<number>(0)

  const unreadEventsCountFetchStatus = ref<FetchStatus>(FetchStatus.IDLE)

  const eventStream = ref<EventSource | null>(null)

  const fetchUnreadEventsCount = (): Promise<void> => {
    unreadEventsCountFetchStatus.value = FetchStatus.PENDING

    return useApi()
      .get<string>('/event/unreadCount')
      .then((response: string) => {
        unreadEventsCount.value = parseInt(response)

        unreadEventsCountFetchStatus.value = FetchStatus.SUCCESS
      })
      .catch(() => {
        unreadEventsCountFetchStatus.value = FetchStatus.ERROR
      })
  }

  const setUnreadEventsCount = (count: number): void => {
    unreadEventsCount.value = count
  }

  const hasEventStream = (): boolean => {
    return null !== eventStream.value
  }

  const openEventStream = (): void => {
    eventStream.value = useApi().sse('/event/subscribe')

    eventStream.value.onmessage = (messageEvent: MessageEvent): void => {
      const event: EventDto = JSON.parse(messageEvent.data)

      setUnreadEventsCount(++unreadEventsCount.value)

      useEventBus().emit('eventCreated', event)
    }
  }

  useEventBus().on('logout', (): void => {
    setUnreadEventsCount(0)
    unreadEventsCountFetchStatus.value = FetchStatus.IDLE

    eventStream.value!.close()
    eventStream.value = null
  })

  return {
    unreadEventsCount,
    unreadEventsCountFetchStatus,
    fetchUnreadEventsCount,
    setUnreadEventsCount,
    hasEventStream,
    openEventStream,
  }
})
