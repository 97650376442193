import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import type { UserDto } from 'core'
import { useApi } from '~/modules/shared/composables/useApi'
import { useRouter } from '#imports'
import { Route } from '~/modules/shared/types.ts'
import { useEventBus } from '~/modules/shared/composables/useEventBus.ts'

export const useUserStore = defineStore('user', () => {
  const user = ref<UserDto | null>(null)

  const fetchUser = async (): Promise<UserDto> => {
    return useApi()
      .get<UserDto>('/user/me')
      .then((response: UserDto) => (user.value = response))
  }

  const hasUser = (): boolean => {
    return null !== user.value
  }

  const resetUser = (): void => {
    user.value = null
  }

  watch(user, (newUser: UserDto | null): void => {
    if (null === newUser) {
      useRouter()
        .push({ name: Route.LOGIN })
        .then(() => useEventBus().emit('logout'))
    }
  })

  return {
    user,
    fetchUser,
    hasUser,
    resetUser,
  }
})
