import { default as LoginYQlV9HqTDcMeta } from "/srv/www/watchpoint/prod/apps/web/src/modules/auth/ui/pages/Login.vue?macro=true";
import { default as index3PM8Df9oVRMeta } from "/srv/www/watchpoint/prod/apps/web/src/modules/device/ui/pages/DeviceMap/index.vue?macro=true";
import { default as indexJYe8huQAQjMeta } from "/srv/www/watchpoint/prod/apps/web/src/modules/device/ui/pages/DevicesSettingsList/index.vue?macro=true";
import { default as index2tUAn9W0YFMeta } from "/srv/www/watchpoint/prod/apps/web/src/modules/device/ui/pages/DeviceSettingsForm/Tab/General/index.vue?macro=true";
import { default as indexuoKzSj4lZGMeta } from "/srv/www/watchpoint/prod/apps/web/src/modules/device/ui/pages/DeviceSettingsForm/Tab/Command/index.vue?macro=true";
import { default as indexgnTpmk99OTMeta } from "/srv/www/watchpoint/prod/apps/web/src/modules/device/ui/pages/DeviceSettingsForm/index.vue?macro=true";
import { default as indexhvTrM5KRGkMeta } from "/srv/www/watchpoint/prod/apps/web/src/modules/event/ui/pages/EventList/index.vue?macro=true";
import { default as indexJk00KzsYvdMeta } from "/srv/www/watchpoint/prod/apps/web/src/modules/telemetry/ui/pages/DevicesAnalyticsList/index.vue?macro=true";
import { default as indexaJ6cc1ZJGoMeta } from "/srv/www/watchpoint/prod/apps/web/src/modules/telemetry/ui/pages/DeviceAnalyticsGraph/index.vue?macro=true";
export default [
  {
    name: "login",
    path: "/login",
    meta: { ...(LoginYQlV9HqTDcMeta || {}), ...{"title":"Вхід в обліковий запис","guestOnly":true} },
    component: () => import("/srv/www/watchpoint/prod/apps/web/src/modules/auth/ui/pages/Login.vue")
  },
  {
    name: "device-map",
    path: "/",
    meta: {"title":"Моніторинг"},
    component: () => import("/srv/www/watchpoint/prod/apps/web/src/modules/device/ui/pages/DeviceMap/index.vue")
  },
  {
    name: "devices-settings-list",
    path: "/settings",
    meta: {"title":"Налаштування"},
    component: () => import("/srv/www/watchpoint/prod/apps/web/src/modules/device/ui/pages/DevicesSettingsList/index.vue")
  },
  {
    name: indexgnTpmk99OTMeta?.name,
    path: "/settings/:deviceId",
    component: () => import("/srv/www/watchpoint/prod/apps/web/src/modules/device/ui/pages/DeviceSettingsForm/index.vue"),
    children: [
  {
    name: "device-general-settings",
    path: "",
    meta: {"name":"Загальні налаштування"},
    component: () => import("/srv/www/watchpoint/prod/apps/web/src/modules/device/ui/pages/DeviceSettingsForm/Tab/General/index.vue")
  },
  {
    name: "device-command-settings",
    path: "command",
    meta: {"name":"Дистанційне керування"},
    component: () => import("/srv/www/watchpoint/prod/apps/web/src/modules/device/ui/pages/DeviceSettingsForm/Tab/Command/index.vue")
  }
]
  },
  {
    name: "event-list",
    path: "/events",
    meta: {"title":"Події"},
    component: () => import("/srv/www/watchpoint/prod/apps/web/src/modules/event/ui/pages/EventList/index.vue")
  },
  {
    name: "devices-analytics-list",
    path: "/analytics",
    meta: {"title":"Аналітика"},
    component: () => import("/srv/www/watchpoint/prod/apps/web/src/modules/telemetry/ui/pages/DevicesAnalyticsList/index.vue")
  },
  {
    name: "device-analytics-graph",
    path: "/analytics/:deviceId/graph",
    component: () => import("/srv/www/watchpoint/prod/apps/web/src/modules/telemetry/ui/pages/DeviceAnalyticsGraph/index.vue")
  }
]