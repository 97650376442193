import { defineNuxtRouteMiddleware } from '#imports'
import { useDeviceStore } from '~/modules/device/stores/device'
import { useTelemetryStore } from '~/modules/telemetry/store/telemetry'
import { useDeviceStatusStore } from '~/modules/device/stores/deviceStatus'
import { useUserStore } from '~/modules/auth/stores/user.ts'

export default defineNuxtRouteMiddleware(async (): Promise<void> => {
  if (!useUserStore().hasUser()) {
    return
  }

  const { fetchDevices } = useDeviceStore()
  const { hasTelemetryStream, openTelemetryStream, fetchTelemetries }
    = useTelemetryStore()
  const { initDeviceStatuses } = useDeviceStatusStore()

  if (!hasTelemetryStream()) {
    await Promise.all([fetchDevices(), fetchTelemetries()])

    openTelemetryStream()
    initDeviceStatuses()
  }
  else {
    fetchDevices()
  }
})
