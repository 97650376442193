export enum DeviceStatus {
  HAS_FAULTS = 'hasFaults',
  HAS_NO_UPDATES = 'hasNoUpdates',
  HAS_NO_VOLTAGE = 'hasNoVoltage',
  HAS_OPEN_DOORS = 'hasOpenDoors',
  HAS_POWER_OUTAGE = 'hasPowerOutage',
  HAS_NO_ISSUE = 'hasNoIssue',
}

export interface DeviceStatusConfig {
  color: string
  name: string
}
