import { defineNuxtRouteMiddleware } from '#imports'
import { setTitle } from '~/modules/shared/utils'

export default defineNuxtRouteMiddleware(async (to) => {
  const { title } = to.meta

  if ('string' === typeof title) {
    setTitle(title)
  }
})
