import { defineStore } from 'pinia'
import { computed, type ComputedRef, reactive, ref } from 'vue'
import type { DeviceDto, TelemetryDto } from 'core'
import { useApi } from '~/modules/shared/composables/useApi'
import { useEventBus } from '~/modules/shared/composables/useEventBus.ts'

export const useTelemetryStore = defineStore('telemetry', () => {
  const telemetries = reactive<Record<string, TelemetryDto>>({})

  const telemetryStream = ref<EventSource | null>(null)

  const fetchTelemetries = (): Promise<void> => {
    return useApi()
      .get<TelemetryDto[]>('/telemetry/latest')
      .then((response: TelemetryDto[]) => {
        response.forEach((telemetry: TelemetryDto): void => {
          telemetries[telemetry.deviceId] = telemetry
        })
      })
  }

  const hasTelemetryStream = (): boolean => {
    return null !== telemetryStream.value
  }

  const openTelemetryStream = (): void => {
    telemetryStream.value = useApi().sse('/telemetry/subscribe')

    telemetryStream.value.onmessage = (event: MessageEvent): void => {
      const telemetry: TelemetryDto = JSON.parse(event.data)

      telemetries[telemetry.deviceId] = telemetry
    }
  }

  const getDeviceTelemetry = (
    device: DeviceDto,
  ): ComputedRef<TelemetryDto | null> => {
    return computed(() => telemetries[device.id] || null)
  }

  useEventBus().on('logout', (): void => {
    Object.keys(telemetries).forEach((key: string): void => {
      delete telemetries[key]
    })

    telemetryStream.value!.close()
    telemetryStream.value = null
  })

  return {
    telemetries,
    fetchTelemetries,
    hasTelemetryStream,
    openTelemetryStream,
    getDeviceTelemetry,
  }
})
